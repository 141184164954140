<template>
  <form
    style="padding: 0"
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.NAME')} (*)`"
        :placeholder="$t('COMMON.NAME')"
        v-model="location.name"
        @change="onFormChanged"
      />
      <validation-error :errors="apiValidationErrors.name" />
    </div>

    <!-- Excerpt -->
    <div class="form-wrapper full">
      <base-input
        :label="`${$t('COMMON.EXCERPT')}`"
        :placeholder="$t('COMMON.EXCERPT')"
      >
        <html-editor v-model="location.excerpt" @change="onFormChanged()">
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>

    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm btn-warning mr-auto"
        :disabled="loading"
        @click="closeForm"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{ $t("COMMON.CANCEL") }}
      </base-button>

      <base-button
        type="button"
        native-type="submit"
        class="btn btn-sm elite-submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading" />
        {{
          location?.id
            ? $t("LOCATIONS.EDIT_LOCATION")
            : $t("LOCATIONS.ADD_LOCATION")
        }}
      </base-button>
    </div>
  </form>
</template>
<script>
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import ValidationError from "@/components/ValidationError.vue";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["locationData", "formErrors", "loading", "closeForm"],

  data() {
    return {
      location: { ...this.locationData },
    };
  },

  created() {},

  methods: {
    async handleSubmit() {
      const locationData = cloneDeep(this.location);
      const me = this.$store.getters["profile/me"];
      if (me?.organization?.id) {
        locationData.organization.id = me.organization?.id;
      }

      if (!locationData?.organization?.id) {
        delete locationData.organization;
      }
      if (!locationData?.manager?.id) {
        delete locationData?.manager;
      }
      if (locationData?.reseller) {
        if (!locationData.reseller?.id) {
          delete locationData.reseller;
        }
      }
      this.$emit("locationSubmitted", locationData);
    },
    onFormChanged() {
      this.$emit("formChanged");
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    locationData(locationData) {
      if (locationData) {
        this.location = { ...this.location, ...cloneDeep(locationData) };
      }
    },
  },
};
</script>
